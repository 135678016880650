import React from "react";
import { useTranslation } from "react-i18next";
import "./cv.css";

const Cv = () => {
  const { t } = useTranslation();

  return (
    <section id="cv" className="cv">
      <div className="cv container">
        <div className="section-title">
          <h2>{t('cv title')}</h2>
        </div>
        <div className="curriculum-items">

          {/* <div className="curriculum-item icon-box" data-aos="fade-up" data-aos-delay="100">
            <a href={t("cv link")} target="_blank" rel="noreferrer">
              <div className="icon">
                <i className="icofont-page"></i>
              </div>
              <h4 className="title">
                <a href={t("cv link")} target="_blank" rel="noreferrer">
                {t('cv curriculum')}
                </a>
              </h4>
              <p>
                {t('cv curriculum description')}
              </p>
            </a>
          </div> */}

          <div className="curriculum-item icon-box" data-aos="fade-up" data-aos-delay="100">
            <a href="https://resume.github.io/?lucasri-silva" target="_blank" rel="noreferrer">
              <div className="icon">
                <i className="bx bxl-github"></i>
              </div>
              <h4 className="title">
                <a href="https://resume.github.io/?lucasri-silva" target="_blank" rel="noreferrer">
                {t('cv github resume')}
                </a>
              </h4>
              <p>
              {t('cv github resume description')}
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cv;