import React from "react";
import { useTranslation } from "react-i18next";
import "./education.css";
import cefetImage from "../../assets/images/education/cefet.png";
import ipbImage from "../../assets/images/education/ipb.png";

const Education = () => {
  const { t } = useTranslation();

  return (
    <section id="education" className="education">
      <div className="education container">
        <div className="section-title">
          <h2>{t("education title")}</h2>
        </div>

        <div className="education__container">
          <div className="education__course container grid">
            <div className="education__img-container">
              <a
                href="https://estig.ipb.pt/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="education__img"
                  src={ipbImage}
                  alt="IPB logo"
                />
              </a>
            </div>

            <div className="education__course-info">
              <h5>
                <em>{t("ipbEducation degree")}</em>
              </h5>
              <h4>
                <em>{t("education gpa")}: --</em>
              </h4>
              <div style={{ clear: "both" }}>
                <span className="date">
                  <em>{t("ipbEducation date")}</em>
                </span>
                <span className="location">
                  <em>{t("ipbEducation location")}</em>
                </span>
              </div>
            </div>
          </div>
          <div className="education__coursework">
            <h6>{t("education coursework")}</h6>
            <ul>
              <li>{t("ipbEducation class 1")}</li>
              <li>{t("ipbEducation class 2")}</li>
              <li>{t("ipbEducation class 3")}</li>
              <li>{t("ipbEducation class 4")}</li>
              <li>{t("ipbEducation class 5")}</li>
            </ul>
          </div>
        </div>

        <div className="education__container">
          <div className="education__course container grid">
            <div className="education__img-container">
              <a
                href="https://www.divinopolis.cefetmg.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="education__img"
                  src={cefetImage}
                  alt="CEFET-MG logo"
                />
              </a>
            </div>

            <div className="education__course-info">
              <h5>
                <em>{t("cefetEducation degree")}</em>
              </h5>
              <h4>
                <em>{t("education gpa")}: 85.0/100</em>
              </h4>
              <div style={{ clear: "both" }}>
                <span className="date">
                  <em>{t("cefetEducation date")}</em>
                </span>
                <span className="location">
                  <em>{t("cefetEducation location")}</em>
                </span>
              </div>
            </div>
          </div>
          <div className="education__coursework">
            <h6>{t("education coursework")}</h6>
            <ul>
              <li>{t("cefetEducation class 1")}</li>
              <li>{t("cefetEducation class 2")}</li>
              <li>{t("cefetEducation class 3")}</li>
              <li>{t("cefetEducation class 4")}</li>
              <li>{t("cefetEducation class 5")}</li>
              <li>{t("cefetEducation class 6")}</li>
              <li>{t("cefetEducation class 7")}</li>
              <li>{t("cefetEducation class 8")}</li>
              <li>{t("cefetEducation class 9")}</li>
              <li>{t("cefetEducation class 10")}</li>
              <li>{t("cefetEducation class 11")}</li>
              <li>{t("cefetEducation class 12")}</li>
              <li>{t("cefetEducation class 13")}</li>
              <li>{t("cefetEducation class 14")}</li>
              <li>{t("cefetEducation class 15")}</li>
              <li>{t("cefetEducation class 16")}</li>
              <li>{t("cefetEducation class 17")}</li>
              <li>{t("cefetEducation class 18")}</li>
              <li>{t("cefetEducation class 19")}</li>
              <li>{t("cefetEducation class 20")}</li>
              <li>{t("cefetEducation class 21")}</li>
              <li>{t("cefetEducation class 22")}</li>
              <li>{t("cefetEducation class 23")}</li>
              <li>{t("cefetEducation class 24")}</li>
              <li>{t("cefetEducation class 25")}</li>
              <li>{t("cefetEducation class 26")}</li>
              <li>{t("cefetEducation class 27")}</li>
              <li>{t("cefetEducation class 28")}</li>
              <li>{t("cefetEducation class 29")}</li>
              <li>{t("cefetEducation class 30")}</li>
              <li>{t("cefetEducation class 31")}</li>
              <li>{t("cefetEducation class 32")}</li>
            </ul>
          </div>
        </div>

      </div>
    </section >
  );
};

export default Education;
