import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import "./contact.css";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_ckfko5o",
      "template_ffo04hp",
      form.current,
      "15mLx64AiSId9vFII"
    );
    e.target.reset();
  };

  return (
    <section className="contact" id="contact">
      <div className="contact container">
        <div className="section-title">
          <h2>{t('contact title')}</h2>
        </div>

        <div className="contact__container grid">
          <div className="contact__content">
            <div className="contact__info">
              <div className="contact__card">
                <i className="contact__card-icon"></i>
                <svg
                  fill="var(--title-color)"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.025 17H3.707l5.963-5.963L12 12.83l2.33-1.794 1.603 1.603a5.463 5.463 0 0 1 1.004-.41l-1.808-1.808L21 5.9v6.72a5.514 5.514 0 0 1 1 .64V5.5A1.504 1.504 0 0 0 20.5 4h-17A1.504 1.504 0 0 0 2 5.5v11A1.5 1.5 0 0 0 3.5 18h9.525c-.015-.165-.025-.331-.025-.5s.01-.335.025-.5zM3 16.293V5.901l5.871 4.52zM20.5 5c.009 0 .016.005.025.005L12 11.57 3.475 5.005c.009 0 .016-.005.025-.005zm-2 8a4.505 4.505 0 0 0-4.5 4.5 4.403 4.403 0 0 0 .05.5 4.49 4.49 0 0 0 4.45 4h.5v-1h-.5a3.495 3.495 0 0 1-3.45-3 3.455 3.455 0 0 1-.05-.5 3.498 3.498 0 0 1 5.947-2.5H20v.513A2.476 2.476 0 0 0 18.5 15a2.5 2.5 0 1 0 1.733 4.295A1.497 1.497 0 0 0 23 18.5v-1a4.555 4.555 0 0 0-4.5-4.5zm0 6a1.498 1.498 0 0 1-1.408-1 1.483 1.483 0 0 1-.092-.5 1.5 1.5 0 0 1 3 0 1.483 1.483 0 0 1-.092.5 1.498 1.498 0 0 1-1.408 1zm3.5-.5a.5.5 0 0 1-1 0v-3.447a3.639 3.639 0 0 1 1 2.447z" />
                  <path fill="none" d="M0 0h24v24H0z" />
                </svg>
                <h3 className="contact__card-title">Email</h3>
                <span className="contact__card-data">
                  lucasrsilva.dev@gmail.com
                </span>

                <a
                  href="mailto:lucasrsilva.dev@gmail.com"
                  className="contact__button"
                >
                  {t('contact write me')}{" "}
                </a>
              </div>

              <div className="contact__card">
                <i className="contact__card-icon"></i>

                <svg
                  fill="var(--title-color)"
                  width="25px"
                  height="25px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                >
                  <path
                    d="M426.7,341.3c-29.1,0-54.8,14.6-70.2,36.9L167.4,278.1c1.9-7.1,3.3-14.4,3.3-22.1c0-7.7-1.3-15-3.3-22.1l189.1-100.1
	c15.4,22.3,41,36.9,70.2,36.9c47.1,0,85.3-38.2,85.3-85.3S473.8,0,426.7,0s-85.3,38.2-85.3,85.3c0,0.8,0.2,1.6,0.2,2.4l-199,105.4
	c-15.1-13.8-35.1-22.4-57.2-22.4C38.2,170.6,0,208.9,0,256s38.2,85.3,85.3,85.3c22.1,0,42-8.6,57.2-22.4l199,105.4
	c0,0.9-0.2,1.6-0.2,2.4c0,47.1,38.2,85.3,85.3,85.3s85.3-38.2,85.3-85.3S473.8,341.3,426.7,341.3z M426.7,42.7
	c23.5,0,42.7,19.1,42.7,42.7c0,23.5-19.1,42.7-42.7,42.7c-23.5,0-42.7-19.1-42.7-42.7C384,61.8,403.1,42.7,426.7,42.7z M85.3,298.7
	c-23.5,0-42.7-19.1-42.7-42.7c0-23.5,19.1-42.7,42.7-42.7c23.5,0,42.7,19.1,42.7,42.7C128,279.5,108.9,298.7,85.3,298.7z
	 M426.7,469.3c-23.5,0-42.7-19.1-42.7-42.7c0-23.5,19.1-42.7,42.7-42.7c23.5,0,42.7,19.1,42.7,42.7
	C469.3,450.2,450.2,469.3,426.7,469.3z"
                  />
                </svg>
                <h3 className="contact__card-title">{t('contact social profiles')}</h3>
                <div className="social-links">
                  <a
                    href="https://github.com/lucasri-silva"
                    className="social-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      fill="var(--title-color)"
                      width="25px"
                      height="25px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22,12.247a10,10,0,0,1-6.833,9.488c-.507.1-.687-.214-.687-.481,0-.328.012-1.407.012-2.743a2.386,2.386,0,0,0-.679-1.852c2.228-.248,4.566-1.093,4.566-4.935a3.859,3.859,0,0,0-1.028-2.683,3.591,3.591,0,0,0-.1-2.647s-.838-.269-2.747,1.025a9.495,9.495,0,0,0-5.007,0c-1.91-1.294-2.75-1.025-2.75-1.025a3.6,3.6,0,0,0-.1,2.647A3.864,3.864,0,0,0,5.62,11.724c0,3.832,2.334,4.69,4.555,4.942A2.137,2.137,0,0,0,9.54,18a2.128,2.128,0,0,1-2.91-.831A2.1,2.1,0,0,0,5.1,16.142s-.977-.013-.069.608A2.646,2.646,0,0,1,6.14,18.213s.586,1.944,3.368,1.34c.005.835.014,1.463.014,1.7,0,.265-.183.574-.683.482A10,10,0,1,1,22,12.247Z" />
                    </svg>
                  </a>

                  <a
                    href="https://linkedin.com/in/lucas-silva-13b060168/"
                    className="social-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      fill="var(--title-color)"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-271 283.9 256 235.1"
                      width="25px"
                      height="25px"
                    >
                      <g>
                        <rect
                          x="-264.4"
                          y="359.3"
                          width="49.9"
                          height="159.7"
                        />
                        <path
                          d="M-240.5,283.9c-18.4,0-30.5,11.9-30.5,27.7c0,15.5,11.7,27.7,29.8,27.7h0.4c18.8,0,30.5-12.3,30.4-27.7
		C-210.8,295.8-222.1,283.9-240.5,283.9z"
                        />
                        <path
                          d="M-78.2,357.8c-28.6,0-46.5,15.6-49.8,26.6v-25.1h-56.1c0.7,13.3,0,159.7,0,159.7h56.1v-86.3c0-4.9-0.2-9.7,1.2-13.1
		c3.8-9.6,12.1-19.6,27-19.6c19.5,0,28.3,14.8,28.3,36.4V519h56.6v-88.8C-14.9,380.8-42.7,357.8-78.2,357.8z"
                        />
                      </g>
                    </svg>
                  </a>

                  <a
                    href="https://codeforces.com/profile/lucasri-silva"
                    className="social-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      fill="var(--title-color)"
                      width="25px"
                      height="25px"
                      viewBox="0 0 24 24"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ transform: "translateY(2px)" }}
                    >
                      <path d="M4.5 7.5C5.328 7.5 6 8.172 6 9v10.5c0 .828-.672 1.5-1.5 1.5h-3C.673 21 0 20.328 0 19.5V9c0-.828.673-1.5 1.5-1.5h3zm9-4.5c.828 0 1.5.672 1.5 1.5v15c0 .828-.672 1.5-1.5 1.5h-3c-.827 0-1.5-.672-1.5-1.5v-15c0-.828.673-1.5 1.5-1.5h3zm9 7.5c.828 0 1.5.672 1.5 1.5v7.5c0 .828-.672 1.5-1.5 1.5h-3c-.828 0-1.5-.672-1.5-1.5V12c0-.828.672-1.5 1.5-1.5h3z" />
                    </svg>
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCNwLYu8CBChgnil3imfuSsQ"
                    className="social-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      fill="var(--title-color)"
                      width="30px"
                      height="30px"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg" 
                      class="icon"
                      style={{ transform: "translateY(2px)" }}
                    >
                      <path d="M941.3 296.1a112.3 112.3 0 0 0-79.2-79.3C792.2 198 512 198 512 198s-280.2 0-350.1 18.7A112.12 112.12 0 0 0 82.7 296C64 366 64 512 64 512s0 146 18.7 215.9c10.3 38.6 40.7 69 79.2 79.3C231.8 826 512 826 512 826s280.2 0 350.1-18.8c38.6-10.3 68.9-40.7 79.2-79.3C960 658 960 512 960 512s0-146-18.7-215.9zM423 646V378l232 133-232 135z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="contact__content">
            <form ref={form} onSubmit={sendEmail} className="contact__form">
              <div className="contact__form-div">
                <label htmlFor="name" className="contact__form-tag">
                  {t('contact form name')}
                </label>
                <input
                  type="text"
                  name="name"
                  className="contact__form-input"
                  placeholder={t('contact form placeholder name')}
                  required
                />
              </div>

              <div className="contact__form-div">
                <label htmlFor="email" className="contact__form-tag">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="contact__form-input"
                  placeholder={t('contact form placeholder email')}
                  required
                />
              </div>

              <div className="contact__form-div contact__form-area">
                <label htmlFor="project" className="contact__form-tag">
                  {t('contact form project')}
                </label>
                <textarea
                  name="project"
                  cols="30"
                  rows="10"
                  className="contact__form-input"
                  placeholder={t('contact form placeholder project')}
                  required
                  minLength={100}
                ></textarea>
              </div>

              <button type="submit" className="button custom-button">
                {t('contact form button')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
